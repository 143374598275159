const registrationForm = document.querySelector('#registrationForm');
const firstName:HTMLInputElement|null = document.querySelector('#firstName');
const lastName:HTMLInputElement|null = document.querySelector('#lastName');
const email:HTMLInputElement|null = document.querySelector('#email');
const phone:HTMLInputElement|null = document.querySelector('#phone');
const business:HTMLInputElement|null = document.querySelector('#business');
const abn:HTMLInputElement|null = document.querySelector('#abn');
const address1:HTMLInputElement|null = document.querySelector('#address1');
const address2:HTMLInputElement|null = document.querySelector('#address2');
const city:HTMLInputElement|null = document.querySelector('#city');
const state:HTMLSelectElement|null = document.querySelector('#state');
const postcode:HTMLInputElement|null = document.querySelector('#postcode');

function getLocal() {
    if(localStorage.getItem('registrationForm') !== null) {
        const data = JSON.parse(localStorage.getItem('registrationForm')!);
        console.log(data.firstName);
        if(data) {
            if(firstName && !firstName?.value) {
                firstName.value = data.firstName;
            }
            if(lastName && !lastName?.value) {
                lastName.value = data.lastName;
            }
            if(email && !email?.value) {
                email.value = data.email;
            }
            if(phone && !phone?.value) {
                phone.value = data.phone;
            }
            if(business && !business?.value) {
                business.value = data.business;
            }
            if(abn && !abn?.value) {
                abn.value = data.abn;
            }
            if(address1 && !address1?.value) {
                address1.value = data.address1;
            }
            if(address2 && !address2?.value) {
                address2.value = data.address2;
            }
            if(city && !city?.value) {
                city.value = data.city;
            }
            if(state && !state?.value) {
                state.value = data.state;
            }
            if(postcode && !postcode?.value) {
                postcode.value = data.postcode;
            }
        }
    }
}

function setLocal(event: Event) {

    const data = {
        "firstName": firstName?.value ?? null,
        "lastName": lastName?.value ?? null,
        "email": email?.value ?? null,
        "phone": phone?.value ?? null,
        "business": business?.value ?? null,
        "abn": abn?.value ?? null,
        "address1": address1?.value ?? null,
        "address2": address2?.value ?? null,
        "city": city?.value ?? null,
        "state": state?.value ?? null,
        "postcode": postcode?.value ?? null,
    }

    localStorage.setItem('registrationForm', JSON.stringify(data));
}

if(registrationForm !== null) {
    getLocal();
    registrationForm.addEventListener('submit', setLocal);
}