import "../css/app.css";
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus'
import 'lazysizes';
import "./registration-helper";

if(import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Setup Alpine.js
window.Alpine = Alpine
Alpine.plugin(focus);
Alpine.start();